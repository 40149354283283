<template>
    <div>
        <v-row class="secondary" fixed>
            <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                <div class="pa-2">
                    <h4 class="text-uppercase"> 
                        Current Status : {{ job.job_status }}
                        <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="primary"
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-account</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ job.validator.name }} ({{ job.validator.email }})</span>
                        </v-tooltip>
                    </h4>
                </div>
            </v-col>
            <v-col class="text-right">
                <div class="pa-2">
                    <v-btn color="primary mr-3" class="white--text" small
                        @click="finishChecking()"
                        :disabled="$store.state.loading"
                        v-if="job.job_status == 'submitted for checking'"
                        >
                        <v-icon>{{ $store.state.loading ? 'mdi-loading mdi-spin' : 'mdi-check' }}</v-icon> Save and Submit
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-tabs v-model="tab" color="primary">
            <v-tab key="tab1">
                Job Details
            </v-tab>
            <v-tab key="tab2">
                Job Submission Section
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item key="tab1" class="pa-3">
                <v-card>
                    <v-card-text class="grey lighten-3 pa-2">
                        <v-row no-gutters>
                            <v-col :style="{'display': 'flex', 'align-items' : 'center'}">
                                <strong>CUSTOMER DETAILS</strong>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field label="Luntian Ref #" outlined dense v-model="job.lbs_ref_no" readonly hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Client Ref #" outlined dense v-model="job.client_ref_no" readonly hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Version" outlined dense v-model="job.version" readonly hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field label="Submitted By" readonly outlined dense v-model="job.client.name" hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Company" readonly outlined dense v-model="job.company" hide-details></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Submission Date" outlined dense v-model="job.date_submitted" readonly hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mt-5">
                    <v-card-text class="grey lighten-3 pa-2">
                        <strong>JOB DETAILS</strong>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    v-model="job.job_address" 
                                    outlined label="Job Address" 
                                    dense 
                                    readonly
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="8" sm="12">
                                <v-text-field 
                                label="Job Type" 
                                readonly 
                                outlined 
                                dense 
                                :value="job.job_type"
                                hide-details>
                            </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <v-text-field 
                                    label="Priority" 
                                    readonly 
                                    outlined 
                                    dense 
                                    v-model="job.priority"
                                    hide-details>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea label="Notes" dense outlined v-model="job.notes" readonly hide-details></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row class="mt-2">
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>PLANS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.plans" :key="f.id">
                                            <td class="text-blue cursor" @click="openLink('plans', f.job_id, f.type, f.filename)">{{ f.filename }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>DOCUMENTS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="f, idx in job.documents" :key="f.id">
                                            <td class="text-blue cursor" @click="openLink('documents', f.job_id, f.type, f.filename)">
                                                {{ f.filename }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item key="tab2" class="pa-3">
                <v-row>
                    <v-col>
                        <v-card :disabled="job.job_status != 'submitted for checking'">
                            <v-card-text class="grey lighten-3 pa-2">
                                <strong>DETAILS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            v-model="job.region_sz"
                                            :items="regions"
                                            item-text="value"
                                            item-value="id"
                                            label="Region - CZ"
                                            outlined
                                            dense
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="job.floor_basis"
                                            :items="['Slub on Ground', 'Suspended Floor']"
                                            label="Floor Basis"
                                            outlined
                                            dense
                                            hide-details
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            label="Base Heating Load (Mj/M2)" 
                                            outlined 
                                            dense 
                                            v-model="job.base_heating_load"
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field 
                                            label="Base Cooling Load (Mj/M2)" 
                                            outlined 
                                            dense 
                                            v-model="job.base_cooling_load"
                                            hide-details>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea 
                                            hide-details
                                            label="Run Notes"
                                            v-model="job.run_notes"
                                            outlined
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <div>
                                            <label for="">Plan Complexity</label>
                                        </div>
                                        <v-rating
                                            v-model="job.plan_complexity"
                                            color="yellow darken-3"
                                            background-color="grey darken-1"
                                            hover
                                            large
                                            >
                                        </v-rating>
                                        <div>
                                            <small>1 star = Hard to model 5 star = Easy to model</small>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <strong>FR5 FILES</strong>
                                    </v-col>
                                    <v-col class="text-right" cols="12" md="8"
                                        v-if="job.job_status != 'completed'">
                                        <div v-if="new_frate">
                                            <v-btn small color="primary" class="mr-2 white--text" @click="saveFrate()">
                                                <v-icon>mdi-floppy</v-icon> Save
                                            </v-btn>
                                            <v-btn small color="red darken-2 white--text" @click="new_frate = null;"> 
                                                <v-icon>mdi-undo</v-icon> Cancel
                                            </v-btn>
                                        </div>
                                        <div v-if="!new_frate">
                                            <v-btn small color="grey darken-3 mr-2 white--text" @click="addFrate('file')">
                                                <v-icon>mdi-file-plus</v-icon> file
                                            </v-btn>
                                            <v-btn small color="orange" class="darken-2 white--text" @click="addFrate('link')"> 
                                                <v-icon>mdi-link</v-icon> URL
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="new_frate">
                                <div>
                                    <v-file-input
                                        show-size
                                        dense
                                        hide-details
                                        label="FR5 File"
                                        outlined
                                        v-model="new_frate.file"
                                        v-if="new_frate.type == 'file'"
                                    ></v-file-input>
                                    <v-text-field
                                        show-size
                                        dense
                                        hide-details
                                        outlined
                                        label="FR5 File Url"
                                        v-model="new_frate.file"
                                        v-if="new_frate.type == 'link'"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead v-if="job.job_status != 'completed'">
                                        <tr>
                                            <th class="text-left">
                                                File
                                            </th>
                                            <th class="text-left" style="width: 80px;">
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="f, idx in job.frates" :key="f.id">
                                            <td 
                                                class="text-blue pointer"
                                                @click="openLink('frates', f.job_id, f.type, f.filename)">
                                                {{ f.filename }}
                                            </td>
                                            <td  v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <v-btn small icon color="red" @click="deleteFrate(idx, f.id)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card>
                            <v-card-text class="grey lighten-3 pa-2">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <strong>DB FILES</strong>
                                    </v-col>
                                    <v-col class="text-right" cols="12" md="8"  
                                        v-if="job.job_status != 'completed'">
                                        <div v-if="!new_db">
                                            <v-btn small color="grey darken-3 mr-2 white--text" @click="addDB('file')">
                                                <v-icon>mdi-file-plus</v-icon> FILE
                                            </v-btn>
                                            <v-btn small color="orange" class="darken-2 white--text" @click="addDB('link')"> 
                                                <v-icon>mdi-link</v-icon> URL
                                            </v-btn>
                                        </div>
                                        <div v-if="new_db">
                                            <v-btn small color="primary mr-2 white--text" @click="saveDB()">
                                                <v-icon>mdi-floppy</v-icon> Save
                                            </v-btn>
                                            <v-btn small color="red darken-2 white--text" @click="new_db = null;"> 
                                                <v-icon>mdi-undo</v-icon> Cancel
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="new_db">
                                <div>
                                    <v-file-input
                                        show-size
                                        dense
                                        hide-details
                                        outlined
                                        label="DB File"
                                        v-model="new_db.file"
                                        v-if="new_db.type == 'file'"
                                    ></v-file-input>
                                    <v-text-field
                                        show-size
                                        dense
                                        hide-details
                                        outlined
                                        label="DB File Url"
                                        v-model="new_db.file"
                                        v-if="new_db.type == 'link'"
                                    ></v-text-field>
                                </div>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead v-if="job.job_status != 'completed'">
                                        <tr>
                                            <th class="text-left">
                                                File
                                            </th>
                                            <th class="text-left" style="width: 80px;">
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="d, idx in job.dbs" :key="d.id">
                                            <td class="text-blue pointer"
                                                @click="openLink('dbs', d.job_id, d.type, d.filename)">
                                                {{ d.filename }}
                                            </td>
                                            <td v-if="['started', 'resumed', 'for revision'].indexOf(job.job_status) >= 0">
                                                <v-btn small icon color="red" @click="deleteDB(idx, d.id)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card :disabled="job.job_status != 'submitted for checking'">
                            <v-card-text class="primary white--text pa-2">
                                <strong>CHECKER REMARKS</strong>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                                <editor :content.sync="job.checker_remarks" @update="updateRemarks"></editor>
                                <!-- <v-textarea label="Add Remarks" outlined dense hide-details v-model="job.checker_remarks"></v-textarea> -->
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog width="500" v-model="view_revise_options">
            <v-card dark>
                <v-card-title>
                    Job Revision
                </v-card-title>
                <v-card-text>
                    Do you want to revise this job?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn small color="orange" class="darken-2" @click="edit_mode = true; view_revise_options = false;">Yes, I will do the revision</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small color="red" class="darken-4" @click="submitRevisionMark()">No, I want to send it back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .text-blue {
        color : blue;
    }
    .cursor {
        cursor: pointer;
    }
</style>
<script>
import axios from 'axios';
import { globalMixins } from '@/mixins/globalMixins';
import CheckerList from '@/components/CheckerList.vue';
import Editor from '@/components/Editor.vue';
export default 
{
    name : 'JobDetails',
    mixins : [globalMixins],
    components : {
        CheckerList,
        Editor
    },
    data() {
        return {
            tab : 'tab1',
            new_frate : null,
            new_db : null,
            job : {
                client : {}
            },
            show_checkers : false,
            staffs : [],
            view_revise_options : false,
            edit_mode : false
        }
    },
    mounted() {
        axios({
            url : process.env.VUE_APP_API_URL + '/jobs/' + this.$route.params.id,
            method : 'GET',
            headers : {
                Authorization : 'Bearer ' + this.$store.getters.getToken
            }
        }).then(response => {
            this.job = response.data;
        });
    },
    methods : {
        startChecking() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/start-checking',
                method : 'PUT',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;

                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        updateRemarks(remarks) {
            this.job.checker_remarks = remarks;
        },
        finishChecking() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/complete',
                method : 'PUT',
                data : this.job,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;
                this.$router.push({name : 'Jobs'});
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        markForRevision() {
            this.view_revise_options = true;
        },
        submitRevisionMark() {
            this.$store.dispatch('loading', true);
            axios({
                url : this.$store.state.api_url + '/jobs/'+ this.job.id +'/revision',
                method : 'PUT',
                params : {action : 'marked for revision'},
                data : this.job,
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(response => {
                this.job = response.data;
                this.$router.push({name : 'Jobs'});
                this.$store.dispatch('loading', false);
            }).catch(err => {
                this.$store.dispatch('loading', false);
            });
        },
        viewFrateFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/frates/' + this.job.id + '/' + filename);
        },
        viewDbFile(filename) {
            window.open(this.$store.state.host_url + '/uploads/dbs/' + this.job.id + '/' + filename);
        },
        addFrate(type) {
            this.new_frate = {
                type : type,
                file : null
            };
        },
        saveFrate() {
            var data = new FormData();
            data.append('type', this.new_frate.type);
            data.append('file', this.new_frate.file);

            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/frates',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.frates.push(res.data.data);
                this.$store.state.loading = false;
                this.new_frate = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            });
        },
        deleteFrate(idx, id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/frates/' + id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.frates.splice(idx, 1);
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.$store.state.loading = false;
                });
            }
        },
        addDB(type) {
            this.new_db = {
                type : type,
                file : null
            };
        },
        saveDB() {
            var data = new FormData();
            data.append('type', this.new_db.type);
            data.append('file', this.new_db.file);

            this.$store.state.loading = true;
            axios({
                url : this.$store.state.api_url + '/jobs/' + this.job.id + '/dbs',
                method : 'POST',
                data : data,
                headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                this.job.dbs.push(res.data.data);
                this.$store.state.loading = false;
                this.new_db = null;
            }).catch(err => {
                alert(JSON.stringify(err));
                this.$store.state.loading = false;
            });
        },
        deleteDB(idx, id) {
            if(confirm('Are you sure?')) {
                this.$store.state.loading = true;
                axios({
                    url : this.$store.state.api_url + '/jobs/' + this.job.id + '/dbs/' + id,
                    method : 'DELETE',
                    headers : {
                        'Authorization' : 'Bearer ' + this.$store.getters.getToken
                    }
                }).then(res => {
                    this.job.dbs.splice(idx, 1);
                    this.$store.state.loading = false;
                }).catch(err => {
                    alert(JSON.stringify(err));
                    this.$store.state.loading = false;
                });
            }
        },
    }
}
</script>